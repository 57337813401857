import { Button, Col, Input, Popover, Row, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import SelectLazy from 'src/components/SelectLazy';
import { ISVGFilter } from '../../../../components/svg';
import InputSearch from '../InputSearch';
import SelectGetAPI from '../SelectGetAPI';
import CSYTSelectGetAPI from './SelectGetAPI';
import { ButtonFilter, DatePickerCustom, DateRangePickerCustom, IconSearch, IconTrash, SelectCustom } from './styled';
import './styles.scss';

function ModalFilter(props) {
    const { listFilter, onClosePopup } = props;
    const [filter, setFilter] = useState();
    const [filterPopup, setFilterPopup] = useState({});
    const [visible, setVisible] = useState();

    useEffect(() => {
        setFilter((prev) => (prev ? prev : props.filter));
    }, [props.filter]);

    useEffect(() => {
        if (onClosePopup) {
            onClosePopup();
        }
    }, [visible]);

    useEffect(() => {
        if (props.getFilterPopup) {
            props.getFilterPopup(filterPopup);
        }
    }, [filterPopup]);

    const onChangeFilter = (key, value, search) => {
        setFilter((prev) => {
            props.onSearch({ ...prev, [key]: typeof value == 'undefined' ? null : value });
            setFilterPopup({ ...prev, [key]: typeof value == 'undefined' ? null : value });
            return { ...prev, [key]: typeof value == 'undefined' ? null : value };
        });
    };
    const onChangeFilterPopup = (key, value, search) => {
        setFilterPopup((prev) => ({ ...prev, [key]: typeof value == 'undefined' ? null : value }));
    };

    const onSearchPopup = () => {
        setFilter(filterPopup);
        props.onSearch(filterPopup);
        setVisible(false);
    };
    const renderDateRangePicker = (propsCustom, name, label, disabledDate, isPopup, value) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <DateRangePickerCustom
                    onChange={(dates) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, dates);
                        } else {
                            onChangeFilter(name, dates);
                        }
                    }}
                    separator={
                        isPopup ? (
                            filterPopup && filterPopup[name]?.length ? (
                                <span> - </span>
                            ) : (
                                <span> </span>
                            )
                        ) : filter && filter[name]?.length > 0 ? (
                            <span> - </span>
                        ) : (
                            <span> </span>
                        )
                    }
                    placeholder={label}
                    dropdownClassName={'rangepicker-custom-1'}
                    value={isPopup ? (filterPopup ? filterPopup[name] : null) : filter ? filter[name] : null}
                    format={['DD/MM/YYYY', 'YYYY', 'DDMMYYYY', 'DD-MM-YYYY']}
                    disabledDate={disabledDate}
                    allowClear={true}
                    style={{ width: '100%' }}
                    {...propsCustom}
                />
            </Tooltip>
        );
    };

    const renderDatePicker = (propsCustom, name, label, disabledDate, isPopup, value) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <DatePickerCustom
                    onChange={(dates) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, dates);
                        } else {
                            onChangeFilter(name, dates);
                        }
                    }}
                    separator={
                        isPopup ? (
                            filterPopup && filterPopup[name]?.length ? (
                                <span> - </span>
                            ) : (
                                <span> </span>
                            )
                        ) : filter && filter[name]?.length > 0 ? (
                            <span> - </span>
                        ) : (
                            <span> </span>
                        )
                    }
                    placeholder={label}
                    dropdownClassName={'rangepicker-custom-1'}
                    value={isPopup ? (filterPopup ? filterPopup[name] : null) : filter ? filter[name] : null}
                    format={['DD/MM/YYYY', 'YYYY', 'DDMMYYYY', 'DD-MM-YYYY']}
                    disabledDate={disabledDate}
                    allowClear={true}
                    style={{ width: '100%' }}
                    {...propsCustom}
                />
            </Tooltip>
        );
    };

    const renderDateReport = (propsCustom, name, label, disabledDate, isPopup, value) => {
        const dataDF = filterPopup[name] || value;
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <DateRangePickerCustom
                    onChange={(dates) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, dates);
                        } else {
                            onChangeFilter(name, dates);
                        }
                    }}
                    separator={
                        isPopup ? (
                            filterPopup && filterPopup[name]?.length ? (
                                <span> - </span>
                            ) : (
                                <span> </span>
                            )
                        ) : filter && filter[name]?.length > 0 ? (
                            <span> - </span>
                        ) : (
                            <span> </span>
                        )
                    }
                    placeholder={label}
                    dropdownClassName={'rangepicker-custom-1'}
                    value={dataDF}
                    format={['DD/MM/YYYY', 'YYYY', 'DDMMYYYY', 'DD-MM-YYYY']}
                    disabledDate={disabledDate}
                    allowClear={true}
                    style={{ width: '100%' }}
                    {...propsCustom}
                />
            </Tooltip>
        );
    };

    const renderInput = (propsCustom, name, label, isPopup) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <Input
                    placeholder={label}
                    value={isPopup ? (filterPopup ? filterPopup[name] : null) : filter ? filter[name] : null}
                    onChange={(e) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, e.target.value);
                        } else {
                            onChangeFilter(name, e.target.value);
                        }
                    }}
                    {...propsCustom}
                />
            </Tooltip>
        );
    };

    const renderSelect = (propsCustom, name, label, list, isPopup, allowClear = true) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <SelectCustom
                    value={
                        isPopup
                            ? filterPopup
                                ? propsCustom?.mode === 'multiple'
                                    ? filterPopup[name] || []
                                    : filterPopup[name]
                                : null
                            : filter
                            ? propsCustom?.mode === 'multiple'
                                ? filter[name] || []
                                : filter[name]
                            : null
                    }
                    onChange={(value, v) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, value);
                        } else {
                            onChangeFilter(name, value);
                        }
                    }}
                    style={{ width: '100%' }}
                    placeholder={label}
                    maxTagCount="responsive"
                    showSearch
                    optionFilterProp="children"
                    allowClear={allowClear}
                    size="default"
                    showArrow
                    {...propsCustom}
                >
                    {list?.map((item, index) => (
                        <SelectCustom.Option value={item?.id} index={index} key={index}>
                            {item?.name}
                        </SelectCustom.Option>
                    ))}
                </SelectCustom>
            </Tooltip>
        );
    };

    const renderSelectGetAPI = (propsCustom, key, label, apiUrl, isPopup) => {
        const pageDefault = propsCustom?.firstPage === 0 ? 0 : 1;
        return (
            <>
                <Tooltip title={propsCustom?.tooltip || label} getPopupContainer={(triggerNode) => triggerNode}>
                    <div>
                        {key !== 'hospital' ? (
                            <SelectGetAPI
                                nameOption={
                                    propsCustom?.option ? (item) => item[propsCustom?.option] : (item) => item.name
                                }
                                valueOption={(item) => item.id}
                                placeholder={label}
                                name={'key'}
                                searchName="name"
                                firstPage={pageDefault}
                                size={20}
                                sizeDefault={propsCustom?.type === 'ISC_Support' ? 200 : 20}
                                apiUrl={apiUrl}
                                set={(name, value) => {
                                    if (isPopup) {
                                        onChangeFilterPopup(key, value ? value : null);
                                    } else {
                                        onChangeFilter(key, value ? value : null);
                                    }
                                }}
                                value={
                                    isPopup
                                        ? filterPopup
                                            ? propsCustom?.mode === 'multiple'
                                                ? filterPopup[key] || []
                                                : filterPopup[key]
                                            : null
                                        : filter
                                        ? propsCustom?.mode === 'multiple'
                                            ? filter[key] || []
                                            : filter[key]
                                        : null
                                }
                                pageDefault={pageDefault}
                                allowClear={true}
                                dropdownMatchSelectWidth={false}
                                search={true}
                                {...propsCustom}
                            ></SelectGetAPI>
                        ) : (
                            <CSYTSelectGetAPI
                                nameOption={
                                    propsCustom?.option ? (item) => item[propsCustom?.option] : (item) => item.name
                                }
                                valueOption={(item) => item.id}
                                placeholder={label}
                                name={'key'}
                                searchName="name"
                                firstPage={pageDefault}
                                size={20}
                                apiUrl={apiUrl}
                                set={(name, value) => {
                                    if (isPopup) {
                                        onChangeFilterPopup(key, value ? value : null);
                                    } else {
                                        onChangeFilter(key, value ? value : null);
                                    }
                                }}
                                // defaultValue={isPopup ? filterPopup ? filterPopup[key] : null : filter ? filter[key] : null}
                                defaultValue={
                                    isPopup
                                        ? filterPopup
                                            ? propsCustom?.mode === 'multiple'
                                                ? filterPopup[key] || []
                                                : filterPopup[key]
                                            : null
                                        : filter
                                        ? propsCustom?.mode === 'multiple'
                                            ? filter[key] || []
                                            : filter[key]
                                        : null
                                }
                                pageDefault={pageDefault}
                                allowClear={true}
                                dropdownMatchSelectWidth={false}
                                search={true}
                                {...propsCustom}
                            ></CSYTSelectGetAPI>
                        )}
                    </div>
                </Tooltip>
            </>
        );
    };

    const renderSelectLazy = (propsCustom, key, label, get, keySearch, isPopup) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <SelectLazy
                    nameOption={(item) => item.name}
                    valueOption={(item) => item.id}
                    placeholder={label}
                    name={key}
                    searchName="name"
                    firstPage={0}
                    size={20}
                    get={get}
                    set={(name, value) => {
                        if (isPopup) {
                            onChangeFilterPopup(name, value ? value : null);
                        } else {
                            onChangeFilter(name, value ? value : null);
                        }
                    }}
                    keySearch="medicalName"
                    allowClear
                    dropdownMatchSelectWidth={false}
                    search={true}
                    defaultValue={
                        isPopup ? (filterPopup ? filterPopup[key]?.id : null) : filter ? filter[key]?.id : null
                    }
                    {...propsCustom}
                />
            </Tooltip>
        );
    };

    const renderInputSearch = (propsCustom, name, label, isPopup) => {
        return (
            <Tooltip title={propsCustom?.tooltip || label}>
                <div>
                    <InputSearch
                        placeholder={label}
                        defaultValue={isPopup ? (filterPopup ? filterPopup[name] : null) : filter ? filter[name] : null}
                        onChange={(value) => {
                            if (isPopup) {
                                onChangeFilterPopup(name, value);
                            } else {
                                onChangeFilter(name, value);
                            }
                        }}
                        {...propsCustom}
                    />
                </div>
            </Tooltip>
        );
    };

    const renderTag = (tag) => {
        switch (tag.type) {
            case 'select-api':
                return (
                    <>
                        {
                            tag?.props?.mode === 'multiple' ? (
                                <>
                                    {filter && filter[tag?.name]?.length
                                        ? filter[tag?.name]?.map((item) => (
                                              <Tooltip title={tag?.label}>
                                                  <Tag
                                                      closable
                                                      onClose={() =>
                                                          onChangeFilter(
                                                              tag?.name,
                                                              filter[tag?.name].filter((x) => x?.id !== item?.id),
                                                          )
                                                      }
                                                  >
                                                      {item?.name}
                                                  </Tag>
                                              </Tooltip>
                                          ))
                                        : null}
                                </>
                            ) : (
                                <>
                                    {tag?.name === 'topicSupport' && filter && filter?.topicSupport ? (
                                        <Tooltip title={tag?.label}>
                                            <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                                {filter && filter?.topicSupport ? filter?.topicSupport?.name : ''}
                                            </Tag>
                                        </Tooltip>
                                    ) : filter && filter[tag?.name] ? (
                                        <Tooltip title={tag?.label}>
                                            <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                                {filter && filter[tag?.name]
                                                    ? filter[tag.name][tag?.props?.option ? tag?.props?.option : 'name']
                                                    : ''}
                                            </Tag>
                                        </Tooltip>
                                    ) : null}
                                </>
                            )
                            // (
                            //     <>
                            //         {filter && filter[tag?.name] ?
                            //             <Tooltip title={tag?.label}>
                            //                 <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                            //                     {filter && filter[tag?.name] ? filter[tag.name][tag?.props?.option ? tag?.props?.option : 'name'] : ''}
                            //                 </Tag>
                            //             </Tooltip>
                            //             : null
                            //         }
                            //     </>
                            // )
                        }
                    </>
                );
            case 'select-lazy':
                return (
                    <>
                        {filter && filter[tag?.name] ? (
                            <Tooltip title={tag?.label}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                    {filter ? filter[tag?.name]?.name : ''}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );
            case 'select':
                return (
                    <>
                        {tag?.props?.mode === 'multiple' ? (
                            <>
                                {filter && filter[tag?.name]?.length
                                    ? filter[tag?.name]?.map((item) => (
                                          <Tooltip title={tag?.label}>
                                              <Tag
                                                  closable
                                                  onClose={() =>
                                                      onChangeFilter(
                                                          tag?.name,
                                                          filter[tag?.name].filter((x) => x !== item),
                                                      )
                                                  }
                                              >
                                                  {tag?.list.find((tagItem) => tagItem.id === item)?.name}
                                              </Tag>
                                          </Tooltip>
                                      ))
                                    : null}
                            </>
                        ) : (
                            <>
                                {filter &&
                                filter[tag?.name] !== null &&
                                filter[tag?.name] !== '' &&
                                filter[tag?.name] !== undefined ? (
                                    <Tooltip title={tag?.label}>
                                        <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                            {tag?.list.find((item) => filter && item.id === filter[tag?.name])?.name}
                                        </Tag>
                                    </Tooltip>
                                ) : null}
                            </>
                        )}
                    </>
                );
            case 'date':
                return (
                    <>
                        {filter && filter[tag?.name]?.length ? (
                            <Tooltip title={tag.tooltip}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, [])}>
                                    {filter[tag?.name][0].format('DD/MM/YYYY')} -{' '}
                                    {filter[tag?.name][1].format('DD/MM/YYYY')}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );
            case 'dateOnly':
                return (
                    <>
                        {filter && filter[tag?.name] ? (
                            <Tooltip title={tag.tooltip}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                    {filter[tag?.name]?.format('DD/MM/YYYY')}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );

            case 'date-report':
                return (
                    <>
                        {filter && filter[tag?.name]?.length ? (
                            <Tooltip title={tag.tooltip}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, [])}>
                                    {filter[tag?.name][0].format('DD/MM/YYYY')} -{' '}
                                    {filter[tag?.name][1].format('DD/MM/YYYY')}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );
            case 'input-search':
                return (
                    <>
                        {filter && filter[tag?.name] && filter[tag?.name] !== '' ? (
                            <Tooltip title={tag?.label}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, '')}>
                                    {filter ? filter[tag?.name] : null}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );
            case 'input':
                return (
                    <>
                        {filter && filter[tag?.name] && filter[tag?.name] !== '' ? (
                            <Tooltip title={tag?.label}>
                                <Tag closable onClose={() => onChangeFilter(tag?.name, null)}>
                                    {filter ? filter[tag?.name] : null}
                                </Tag>
                            </Tooltip>
                        ) : null}
                    </>
                );
        }
    };

    const renderTags = () => {
        return <div className="tags">{listFilter.map((item, index) => renderTag(item))}</div>;
    };

    const content = () => {
        return (
            <div className="filter-popover__content">
                <div className="box-flow">
                    <div className="title">Bộ lọc</div>
                    {listFilter?.map((item, index) => renderFilter(item, true))}
                </div>
                <div className="filter-popover__footer">
                    <Button className="close mr-3" onClick={() => setFilterPopup({})}>
                        <IconTrash />
                        <span>Xóa bộ lọc</span>
                    </Button>
                    <Button className="" onClick={() => onSearchPopup()}>
                        <IconSearch />
                        <span>Tìm</span>
                    </Button>
                </div>
            </div>
        );
    };

    const renderFilter = (item, isPopup = true) => {
        switch (item.type) {
            case 'select-api':
                return renderSelectGetAPI(item.props, item?.name, item?.label, item?.apiUrl, isPopup);
            case 'select-lazy':
                return renderSelectLazy(item.props, item?.name, item?.label, item?.get, item?.keySearch, isPopup);
            case 'select':
                return renderSelect(item.props, item?.name, item?.label, item?.list, isPopup);
            case 'date':
                return renderDateRangePicker(
                    item.props,
                    item?.name,
                    item?.label,
                    item?.disabledDate,
                    isPopup,
                    item?.value,
                );
            case 'dateOnly':
                return renderDatePicker(item.props, item?.name, item?.label, item?.disabledDate, isPopup, item?.value);
            case 'date-report':
                return renderDateReport(item.props, item?.name, item?.label, item?.disabledDate, isPopup, item?.value);
            case 'input-search':
                return renderInputSearch(item.props, item?.name, item?.label, isPopup);
            case 'input':
                return renderInput(item.props, item?.name, item?.label, isPopup);
        }
    };

    return (
        <>
            <Row>
                {listFilter?.length > 5 ? (
                    <Col md={2} xs={24}>
                        <Popover
                            content={content()}
                            onVisibleChange={(event) => {
                                if (event) {
                                    setFilterPopup(filter);
                                }
                                setVisible(event);
                            }}
                            trigger="click"
                            className="filter-popover"
                            overlayClassName="filter-overlay"
                            placement="bottomLeft"
                            visible={visible}
                        >
                            <ButtonFilter style={{ width: '100%', textAlign: 'center' }}>
                                {ISVGFilter()}
                                <span className="ml-2">Bộ lọc</span>
                            </ButtonFilter>
                        </Popover>
                    </Col>
                ) : null}
                {listFilter?.map((item, index) => (
                    <>
                        {index < 5 ? (
                            <Col md={item?.size || 4} xs={24}>
                                {renderFilter(item, false)}
                            </Col>
                        ) : null}
                    </>
                ))}
            </Row>
            {renderTags()}
        </>
    );
}

export default ModalFilter;
