import notificationProvider from '@data-access/notification-provider';
import { nonAccentVietnamese } from '@utils/common';
import { Select } from 'antd';
import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import './styles.scss';

const SelectCustom = styled(Select).attrs((props) => ({
    suffixIcon: !props?.loading ? (
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2946 0.294459C10.9053 -0.0948134 10.2743 -0.0951574 9.88462 0.293691L6.70634 3.46505C6.31599 3.85454 5.68401 3.85454 5.29366 3.46505L2.11538 0.29369C1.72569 -0.0951576 1.09466 -0.0948134 0.705384 0.294459C0.315811 0.684032 0.315811 1.31565 0.705384 1.70523L5.29289 6.29274C5.68342 6.68326 6.31658 6.68326 6.70711 6.29274L11.2946 1.70523C11.6842 1.31565 11.6842 0.684032 11.2946 0.294459Z"
                fill="#101011"
            />
        </svg>
    ) : undefined,
    size: 'default',
}))`
    .ant-select-selection-placeholder {
        width: calc(100% - 40px);
    }
`;
function SelectGetAPI({
    name,
    nameOption = (item) => item?.name || '',
    valueOption = (item) => item?.id || '',
    mode,
    defaultValue = mode === 'multiple' ? [] : undefined,
    placeholder = 'Chọn',
    pageDefault = 0,
    sizeDefault = 20,
    set,
    ref,
    optionAll = false,
    allowClear = false,
    className,
    displaySelect,
    disabled = false,
    optionsDefault = [],
    searchName = name,
    keySearch,
    apiUrl,
    searchValue,
    value,
    type,
    filter,
    ...restProps
}) {
    const [options, setOptions] = useState([...optionsDefault]);
    const [page, setPage] = useState(pageDefault);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isHideAdress, setIsHideAdress] = useState(false);
    const timeoutRef = useRef();

    useEffect(() => {
        if (mode === 'multiple') {
            setOptions((prev) => {
                if (prev?.length) {
                    return uniqBy([...value, ...prev], (item) => {
                        return item.id;
                    });
                } else {
                    return value;
                }
            });
        }
    }, [value]);

    useEffect(() => {
        if(optionsDefault?.length > 0 && options?.length === 0) {
            setOptions(optionsDefault)
        }
    }, [optionsDefault])
    
    const onScroll = (event) => {
        var target = event.target;
        if (!loading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 2) {
            if (page < (pageDefault === 0 ? total - 1 : total)) {
                setPage(prev => {
                    getOptions({page: prev + 1})
                    return prev + 1
                });
            }
        }
    };

    const handleSearch = (value) => {
        let v = value;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            if (v || v == "") {
                getOptions({ page: pageDefault, size: sizeDefault, [searchName]: v });
            } else if (!v) {
                getOptions({ page: pageDefault, size: sizeDefault, [searchName]: '' });
            }
        }, 300);
    };

    const getUrlEndpoint = (params) => {
        let search = params.name;
        let url = apiUrl.replace('{searchValue}', search ? search : '');
        let convertUrl = apiUrl.replace('{searchValue}', search ? search : '');
        if (url.includes('page={page}')) {
            url = url.replace('{page}', params.page);
        } else {
            if (url.includes('page=')) {
                url = url.replace(/page=\d+/, `page=${params.page}`);
            } else {
                url = `${url}&page=${params.page}`;
            }
        }
        if (url.includes('size={size}')) {
            url = url.replace('{size}', sizeDefault);
        } else {
            if (url.includes('size=')) {
                url = url.replace(/size=\d+/, `size=${sizeDefault}`);
            } else {
                url = `${url}&size=${sizeDefault}`;
            }
        }
        convertUrl = url;
        if (searchValue === 'username') {
            if (!search || search === undefined) {
                convertUrl = url.replace('&username=', '');
            } else {
                convertUrl = url;
            }
        }
        return convertUrl;
    };

    const getOptionsEventRef = async (params) => {
        let urlEndPoint = getUrlEndpoint(params);
        try {
            const res = await notificationProvider.getEventRefs(urlEndPoint);
            if (res.content) {
                let data = res?.content || [];
                data = data.map((item) => {
                    return {
                        ...item,
                        id: item.id,
                        name: item?.name,
                    };
                });
                let totalPages = 0;
                if (res.totalPage === 0 && res.totalElements > 20) {
                    totalPages = Math.ceil(res.totalElements / 20);
                } else {
                    totalPages = res.totalPages || res.totalPage;
                }
                return {
                    data,
                    totalPages,
                };
            } else {
                return {
                    data: [],
                    totalPages: 0,
                };
            }
        } catch (err) {
            return {
                data: [],
                totalPage: 0,
            };
        }
    };

    const formatData = (data, filter, type) => {
        let listTopic = [];
        if (type === 'ISC_Support') {
            if (filter) {
                listTopic = data
                    .filter((item) => item?.category?.type === filter)
                    .map((topic) => {
                        return {
                            id: topic.topicId,
                            value: topic.topicId,
                            name: topic.title.rawText,
                        };
                    });
            }
            return listTopic;
        } else {
            return data;
        }
    };

    const getOptions = async (params = {}) => {
        try {
            setLoading(true);
            const { data, totalPages } = await getOptionsEventRef({
                size: sizeDefault,
                ...params,
            });
            setLoading(false);
            let optionsOld = page == pageDefault ? [] : [...options];
            let newOptions = uniqBy([...optionsOld, ...formatData(data, filter, type)], (item) => {
                return item.id;
            })
            if (value) {
                if (mode !== 'multiple') {
                    newOptions = newOptions?.filter((item) => item?.id !== value?.id);
                    newOptions.unshift(value);
                } else {
                    newOptions = uniqBy([...value, ...newOptions], (item) => {
                        return item.id;
                    });
                }
            }
            newOptions = newOptions.filter((item) => item?.id);
            setOptions(newOptions);
            setTotal(totalPages);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleOnChange = (values, select) => {
        if (mode === 'multiple') {
            set(
                name,
                select?.map((item) => ({
                    name: item.optionValue?.name,
                    id: item.optionValue?.id,
                })) || null,
            );
        } else {
            set(name, select?.optionValue || null);
        }
    };

    const handleFilter = (input, option) => {
        return (
            nonAccentVietnamese((option?.optionValue?.name || '').toLowerCase()).indexOf(
                nonAccentVietnamese(input || '').toLowerCase(),
            ) >= 0
        );
    };

    const renderAddressCSYT = (option) => {
        return (
            <div className='d-flex flex-column selectAddress'>
                <div>{option?.name}</div>
                <div className='adreess' style={{color: '#69788C' }}>{option?.address}</div>
            </div>
        );
    };

    return (
        <SelectCustom
            style={{ width: '100%' }}
            showSearch={true}
            allowClear={allowClear}
            ref={ref}            
            filterOption={handleFilter}
            onPopupScroll={onScroll}
            placeholder={placeholder}
            onSearch={handleSearch}
            onClear={handleSearch}
            onDropdownVisibleChange={(event) => {
                if(event) {
                    setPage(pageDefault)
                    getOptions({page: pageDefault})
                }
            }}
            mode={mode}
            className={className}
            disabled={disabled}
            dropdownClassName="select-infinity"
            // {...restProps}
            value={value ? (mode === 'multiple' ? value.map((item) => item?.id) : value?.id) : null}
            onChange={handleOnChange}
            maxTagCount="responsive"
            showArrow
        >
            {optionAll && (
                <Select.Option key="tat-ca" value="">
                    Tất cả
                </Select.Option>
            )}

            {options?.map((option, index) => (
                <Select.Option key={option?.id || index} value={valueOption(option)} optionValue={option}>
                    {displaySelect === 'hospital'
                        ? renderAddressCSYT(option)
                        : nameOption(option)
                        ? nameOption(option)
                        : option.name}
                </Select.Option>
            ))}
            {/* {options?.map((option, index) => (
                <Select.Option key={option?.id || index} value={valueOption(option)} optionValue={option}>
                    {nameOption(option) ? nameOption(option) : option.name}
                </Select.Option>
            ))} */}
            {loading && (
                <Select.Option key="loading" disabled>
                    Loading...
                </Select.Option>
            )}
        </SelectCustom>
    );
}

export default SelectGetAPI;
