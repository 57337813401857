import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Form, Modal, Button } from "antd";
import React from "react";
import { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userProvider from "../../../../data-access/user-provider";
import dataCacheProvider from "../../../../data-access/datacache-provider";
import constants from "../../../../resources/strings";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const permission = constants.permission;
var md5 = require("md5");

function ChangePass(props, ref) {
    const userId = useSelector((state) => state?.userApp?.currentUser?.id || "");
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const history = useHistory();
    useImperativeHandle(ref, () => ({ onOpen }));

    const [form] = Form.useForm();

    const validatePassword = (_, value) => {
        if (value) {
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]+$/;
            if (value?.length < 8) {
                return Promise.reject('Mật khẩu có độ dài tối thiểu 8 ký tự!');
            }
            if (!regex.test(value)) {
                return Promise.reject('Mật khẩu không được chứa ký tự có dấu!');
            }
            const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!strongRegex.test(value)) {
                return Promise.reject('Mật khẩu tối thiểu 8 ký tự, tối đa 15 ký tự, bao gồm số, chữ thường, chữ in hoa và ký tự đặc biệt!');
            }
            return Promise.resolve();
        } else {
            return Promise.reject('Vui lòng nhập mật khẩu!');
        }
    };

    const onOpen = () => {
        setVisible(true);
    };

    useEffect(() => {
        form.resetFields();
    }, [visible]);

    const handleClose = () => {
        setVisible(false);
    };

    const handleOnSubmit = (data) => {
        const { newpass = "", passold = "", renewpass = "" } = data;
        let param = {
            passwordOld: md5(passold),
            passwordNew: md5(newpass),
        };
        userProvider
            .updatePassword(userId, param)
            .then((res) => {
                switch (res.code) {
                    case 0:
                        let user = res.data.user;
                        user.permission = res.data?.user?.authorities || [];
                        dispatch({
                            type: constants.action.action_user_login,
                            value: user,
                        });
                        dataCacheProvider
                            .save("", constants.key.storage.current_account, user)
                            .then((s) => {
                                if (user.permission.includes("ADMIN")) {
                                    history.push("/admin/dashboard/booking");
                                } else {
                                    history.push(permission[user.permission[0]]);
                                }
                            });
                        handleClose();
                        toast.success("Đổi mật khẩu thành công");
                        return Promise.resolve({
                            status: "success",
                        });
                    default:
                        toast.error(res?.message || "Đổi mật khẩu không thành công");
                        return Promise.resolve({
                            status: "error",
                        });
                }
            })
            .catch((err) => {
                toast.error("Đổi mật khẩu thất bại");
            });
    };

    return (
        <Modal
            title={"Đổi mật khẩu"}
            visible={visible}
            onCancel={handleClose}
            footer={null}
            wrapClassName="change-pass-modal"
            width={700}
        >
            <div>
                <Form onFinish={handleOnSubmit} className="fix-h225" form={form} layout="vertical">
                    <Form.Item
                        label="Mật khẩu cũ"
                        name="passold"
                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu cũ" }]}
                    >
                        <Input.Password type="password" placeholder="Mật khẩu cũ" />
                    </Form.Item>

                    <Form.Item
                        label="Mật khẩu mới"
                        name="newpass"
                        rules={[
                            { validator: validatePassword },
                        ]}>
                        <Input.Password type="password" placeholder="Mật khẩu mới" maxLength={15} />
                    </Form.Item>

                    <Form.Item
                        label="Nhập lại mật khẩu mới"
                        name="renewpass"
                        rules={[
                            { required: true, message: "Vui lòng nhập lại mật khẩu mới" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    let newpassValue = getFieldValue("newpass");
                                    if (value.endsWith(' ')) {
                                        return Promise.reject(new Error("Mật khẩu không được có chứa khoảng trắng"));
                                    }
                                    if (
                                        (value || "").trim() &&
                                        (newpassValue || "").trim() &&
                                        (value || "").trim() !== (newpassValue || "").trim()
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "Mật khẩu mới và nhập lại mật khẩu mới không giống nhau!"
                                            )
                                        );
                                    }

                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            type="password"
                            placeholder="Nhập lại mật khẩu mới"
                            maxLength={15}
                        />
                    </Form.Item>

                    <Form.Item className="change-pass-btns">
                        <Button
                            className="btn-style btn-style2"
                            onClick={handleClose}
                        >
                            <CloseOutlined /> Thoát
                        </Button>

                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-style"
                        >
                            <CheckOutlined /> Lưu lại
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}

export default forwardRef(ChangePass);
