import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import adminProvider from 'src/data-access/admin-provider';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ChangePass_Container = styled.div`
    .row-login {
        max-width: 530px;
    }
    .right-form {
        width: 100%
    }
    .ant-form-item-label {
        overflow: initial;
    }
    .ant-form-item .ant-form-item-label label {
        position: absolute;
        top: -6px;
        z-index: 101;
        font-size: 16px;
        left: 25px;
        background-color: #fff;
        line-height: 15px;
        color: #4a4a4a;
    }
    .ant-input-affix-wrapper {
        height: 54px;
        border: 1px solid #3a352f;
    }
    .ant-form-item-label > label::after {
        content: '';
    }
    .ant-form-item-control-input-content .ant-input-affix-wrapper.ant-input-password {
        border-radius: 6px;
    }
    .ant-form-item-explain-error {
        text-align: left
    }
`;

const ChangePassword = () => {
    const [form] = Form.useForm();
    const id = useParams();
    const WatchPassword = Form.useWatch("password", form);
    const history = useHistory();


    useEffect(() => {
        if (id) {
            try {
                const res = adminProvider.checkTokenForgot(id).then((res) => {
                    if (!res) {
                        toast.error('Đường link đã hết hạn sử dụng, Vui lòng thử lại sau!')
                        history.push('/dang-nhap');
                    }
                })
                    .catch((err) => {
                        toast.error('Đường link đã hết hạn sử dụng, Vui lòng thử lại sau!')
                        history.push('/dang-nhap');
                    });

            } catch (err) {
                history.push('/dang-nhap');
            }
        }
    }, [id]);

    const validatePassword = (_, value) => {
        if (value) {
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\]+$/;
            if (value?.length < 8) {
                return Promise.reject('Mật khẩu có độ dài tối thiểu 8 ký tự!');
            }
            if (!regex.test(value)) {
                return Promise.reject('Mật khẩu không được chứa ký tự có dấu!');
            }
            const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!strongRegex.test(value)) {
                return Promise.reject('Mật khẩu tối thiểu 8 ký tự, tối đa 15 ký tự, bao gồm số, chữ thường, chữ in hoa và ký tự đặc biệt!');
            }
            return Promise.resolve();
        } else {
            return Promise.reject('Vui lòng nhập mật khẩu!');
        }
    };

    const onChangeLastPass = (_, value) => {
        if (value) {
            if (value === WatchPassword) {
                return Promise.resolve();
            } else {
                return Promise.reject('Mật khẩu không khớp. Vui lòng nhập lại!');
            }
        } else {
            return Promise.reject('Vui lòng nhập lại mật khẩu mới!');
        }
    }

    const onSubmit = async (value) => {
        try {
            if (id) {
                let body = { "newPassword": value?.password }
                const res = await adminProvider.changePassword(id, body);
                toast.success('Đổi mật khẩu thành công!')
                history.push('/dang-nhap');
            }
        } catch (err) {
            console.log(err);
            toast.error('Có lỗi xảy ra. Xin vui lòng thử lại !')
        }
    }
    return (
        <ChangePass_Container>
            <Form onFinish={onSubmit} form={form}>
                <div className="app login-page flex-row align-items-center">
                    <div className="container">
                        <div className="row justify-content-center row-login">
                            <div className="right-form form-item">
                                <div className="">
                                    <h1 className="logo-login"><img src="/images/logo-login-new.svg" alt="" /></h1>
                                    <p className="text-muted">Vui lòng nhập lại mật khẩu mới cho tài khoản của bạn!</p>

                                    <Form.Item label='Mật khẩu mới' name='password' style={{ marginTop: 20 }} rules={[
                                        { validator: validatePassword },
                                    ]}>
                                        <Input.Password placeholder="Nhập mật khẩu mới" maxLength={15} />
                                    </Form.Item>
                                    <Form.Item label='Nhập lại mật khẩu mới' name='lastPassword' style={{ marginTop: 20 }} rules={[
                                        { validator: onChangeLastPass },
                                    ]}>
                                        <Input.Password placeholder="Nhập lại mật khẩu mới" maxLength={15} />
                                    </Form.Item>

                                    <div className='d-flex justify-content-end mt-4'>
                                        <button className="button-new btn-login" type="button" onClick={() => form.submit()}>Đặt lại mật khẩu</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </ChangePass_Container>
    )
}

export default ChangePassword
