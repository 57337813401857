import clientUtils from '@utils/client-utils';
import client from '@utils/client-utils';
import constants from '@resources/strings';
import { combineUrlParams } from 'src/utils/helper';

const convertParamsToUrl = (params, baseUrl, hasPageSize = true) => {
    // const {
    //   page,
    //   size,
    //   sort,

    // } = params;
    // return combineUrlParams(baseUrl, {
    //   page: hasPageSize ? page || 0 : undefined,
    //   size: hasPageSize ? size || 20 : undefined,
    //   sort: sort,
    // })
    return combineUrlParams(baseUrl, params);
};
export default {
    getDetailPharmacy(id) {
        return clientUtils.requestApiFix('get', constants.api.pharmacy.detailPharmacies.replace(':id', id));
    },

    getDetailPaymentMethods(id) {
        return clientUtils.requestApiFix('get', constants.api.pharmacy.detailPaymentMethods.replace(':id', id));
    },

    getDetailPaymentMethods(id) {
        return clientUtils.requestApiFix('get', constants.api.pharmacy.detailPaymentMethods.replace(':id', id));
    },

    updatePaymentMethods(id, body) {
        return clientUtils.requestApiFix('put', constants.api.pharmacy.detailPaymentMethods.replace(':id', id), body);
    },

    getPharmacies({ page = 0, size = 20, name = '', status = '', fromDate = '', toDate = '', tag = '' }) {
        const url = `${constants.api.pharmacy.pharmacies}?page=${page}&size=${size}${name ? `&name=${name}` : ''}${
            tag ? `&tag=${tag}` : ''
        }${status ? `&status=${status}` : ''}${fromDate ? `&created=${fromDate}` : ''}${
            toDate ? `,${toDate}` : ''
        }&sort=ld_created,desc`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getPharmacyId(id) {
        const url = constants.api.pharmacy.pharmacies;
        return clientUtils.requestApiFix('get', url + '/' + id).then((res) => res);
    },
    reject(id, body) {
        const url = `${constants.api.pharmacy.pharmacies}/${id}/reject`;
        return clientUtils.requestApiFix('put', url, body).then((res) => res);
    },
    verify(id, body) {
        const url = `${constants.api.pharmacy.pharmacies}/${id}/verify`;
        return clientUtils.requestApiFix('put', url, body).then((res) => res);
    },
    // danh muc thuoc medicine_groups
    getMedicineGroup({ page = 0, size = 20, name = '', invisible = '' }) {
        const url = `${constants.api.pharmacy.medicine_groups}?page=${page}&size=${size}${name ? `&name=${name}` : ''}${
            invisible ? `&invisible=${invisible}` : ''
        }&sort=ld_created,DESC`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    createUpdateMedicineGroup(id = null, body) {
        const url = constants.api.pharmacy.medicine_groups;
        if (id) {
            return clientUtils.requestApiFix('put', url + '/' + id, body).then((res) => res);
        } else {
            return clientUtils.requestApiFix('post', url, body).then((res) => res);
        }
    },
    // Quản lý thuốc medicines
    getMedicines({
        page = 0,
        size = 20,
        name = '',
        status = '',
        pharmacyId = '',
        fromDate = '',
        toDate = '',
        created = '',
        displayType = '',
    }) {
        const requestUrl = combineUrlParams(constants.api.pharmacy.medicines, {
            page,
            size,
            name,
            status,
            pharmacyId,
            fromDate,
            toDate,
            displayType,
            created,
            sort: 'ld_created,desc',
        });
        return clientUtils.requestApiFix('get', requestUrl).then((res) => res);
    },
    rejectMedicine(id, body) {
        const url = `${constants.api.pharmacy.medicines}/${id}/reject`;
        return clientUtils.requestApiFix('put', url, body).then((res) => res);
    },
    verifyMedicine(id, query) {
        const url = `${constants.api.pharmacy.medicines}/${id}/verify`;
        return clientUtils.requestApiFix('put', url, query).then((res) => res);
    },
    // Quản lý đơn hàng orders
    getOrders({
        page = 0,
        size = 20,
        warehouseId = '',
        status = '',
        orderId = '',
        phone = '',
        name = '',
        createdFrom = '',
        createdTo = '',
        completeFrom = '',
        completeTo = '',
        deviceType = null,
        appId = null,
    }) {
        const url = `${constants.api.pharmacy.orders}?page=${page}&size=${size}${
            warehouseId ? `&warehouseId=${warehouseId}` : ''
        }${status ? `&status=${status}` : ''}${orderId ? `&orderId=${orderId}` : ''}${
            phone ? `&recipientPhone=${phone}` : ''
        }${name ? `&name=${name}` : ''}${createdFrom ? `&created=${createdFrom}` : ''}${
            createdTo ? `,${createdTo}` : ''
        }${completeFrom ? `&delivered=${completeFrom}` : ''}${completeTo ? `,${completeTo}` : ''}${
            deviceType ? `&deviceType=${deviceType}` : ''
        }${appId ? `&appId=${appId}` : ''}&sort=ld_created,DESC
    `;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getOrderDetail(id) {
        const url = constants.api.pharmacy.orders + '/' + id;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    cancelOrder(id, body) {
        const url = constants.api.pharmacy.orders + '/' + id + '/events';
        return clientUtils.requestApiFix('put', url, body).then((res) => res);
    },
    cancelOrderv2(id, body) {
        const url = constants.api.pharmacy.orders + '/' + id;
        return clientUtils
            .requestApiFix('delete', url, {
                data: {
                    reason: 'Không đầy đủ thông tin',
                },
            })
            .then((res) => res);
    },
    getPrescriptions({
        page = 0,
        size = 20,
        pharmacyId = '',
        status = '',
        phone = '',
        createdFrom = '',
        createdTo = '',
    }) {
        let url = combineUrlParams(constants.api.pharmacy.prescriptions, {
            page: page,
            size: size,
            pharmacyId: pharmacyId,
            status: status,
            phone: phone ? `&recipientPhone=${phone}` : '',
            created: createdFrom && createdTo ? `${createdFrom},${createdTo}` : '',
            sort: `ld_created,DESC`,
        });
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getPrescriptionsDetail(id) {
        const url = constants.api.pharmacy.prescriptions + '/' + id;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    getPrescriptionsDetailUser(id) {
        if (id) {
            const url = constants.api.pharmacy.getprofile.replace(':userId', id);
            return clientUtils.requestApiFix('get', url).then((res) => res);
        }
    },
    getByIds({ ids = [] }) {
        const url = `${constants.api.pharmacy.byIds}?${ids ? `id=${ids.join(',')}` : ''}`;
        return clientUtils.requestApi('get', url).then((res) => res);
    },
    getMedicineByIds({ ids = [] }) {
        const url = `${constants.api.pharmacy.medicines}?${ids ? `id=${ids.join(',')}` : ''}`;
        return clientUtils.requestApi('get', url).then((res) => res);
    },
    exportExcel({
        page = 0,
        size = 20,
        warehouseId = '',
        status = '',
        orderId = '',
        phone = '',
        name = '',
        createdFrom = '',
        createdTo = '',
        completeFrom,
        completeTo,
        deviceType = null,
        appId = null
    }) {
        let query = {
            page: page,
            size: size,
            warehouseId: warehouseId,
            status: status,
            orderId: orderId,
            recipientPhone: phone,
            name: name,
            created: createdFrom && createdFrom ? createdFrom + ',' + createdTo : '',
            delivered: completeFrom && completeTo ? completeFrom + ',' + completeTo : '',
            deviceType,
            appId
        };

        const url = combineUrlParams(constants.api.pharmacy.exportfile, query);
        return new Promise((resolve, reject) => {
            clientUtils
                .requestApiExcel(
                    'get',
                    url,
                    null,
                    { 'Content-Type': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet' },
                    { responseType: 'arraybuffer' },
                )
                .then((x) => {
                    resolve(x);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    exportCSV({ page, size, warehouseId, status, orderId, phone, name, createdFrom, createdTo }) {
        return new Promise((resolve, reject) => {
            const url = combineUrlParams(constants.api.payment.export, {
                page,
                size,
                warehouseId,
                status,
                orderId,
                recipientPhone: phone,
                name,
                created: createdFrom ? `${createdFrom}${createdTo ? `,${createdTo}` : ''}` : undefined,
                sort: 'ld_created,DESC',
            });
            client
                .requestApiExcel(
                    'get',
                    url,
                    null,
                    { 'Content-Type': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet' },
                    { responseType: 'arraybuffer' },
                )
                .then((s) => {
                    resolve(s);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    getOrder(id) {
        const url = `${constants.api.pharmacy.get_order}${id ? `?orderId=${id}` : ''}`;
        return clientUtils.requestApiFix('get', url).then((res) => res);
    },
    cancelFullFillment(id, body) {
        const url = constants.api.pharmacy.create_order.replace(':orderId', id);
        return clientUtils.requestApiFix('delete', url, body).then((res) => res);
    },
    createFullFillment(id, body) {
        const url = constants.api.pharmacy.create_order.replace(':orderId', id);
        return clientUtils.requestApiFix('post', url, body).then((res) => res);
    },
    getHistory(id) {
        if (id) {
            const url = `${constants.api.pharmacy.get_order}/${id}`;
            return clientUtils.requestApiFix('get', url).then((res) => res);
        }
    },
    updateFullFillmentStatus(id, body) {
        if (id) {
            const url = `${constants.api.pharmacy.get_order}/${id}/events`;
            return clientUtils.requestApiFix('put', url, body);
        }
    },
    addNote({ id, note }) {
        if (id) {
            const url = `${constants.api.pharmacy.orders}/${id}/notes`;
            return clientUtils.requestApiFix('put', url, {
                note: note,
            });
        }
    },
    async getAllHotDeal(params) {
        const url = combineUrlParams(constants.api.pharmacy.getAllHotDeal, params);
        return await clientUtils.requestApiFix('get', url);
    },
    async insertData(id, body) {
        const url = combineUrlParams(constants.api.pharmacy.insertData.replace(':id', id));
        return await clientUtils.requestApiFix('patch', url, body);
    },
    async getDetailHotDeal(id) {
        const url = combineUrlParams(constants.api.pharmacy.hot_deal_groups + `/${id}`);
        return await clientUtils.requestApiFix('get', url);
    },

    async createNewHotDeal(body) {
        return await clientUtils.requestApiFix('post', constants.api.pharmacy.getAllHotDeal, body);
    },

    async deleteHotDeal(id) {
        return await clientUtils.requestApiFix('delete', constants.api.pharmacy.getAllHotDeal + `/${id}`);
    },

    async editHotDeal(id, body) {
        return await clientUtils.requestApiFix('put', constants.api.pharmacy.getAllHotDeal + `/${id}`, body);
    },

    async getAllDeliveryMethods(size = 1000) {
        return await clientUtils.requestApiFix('get', constants.api.pharmacy.getAllMethods + `?size=${size}&sort=id`);
    },

    async createNewMethods(body) {
        return await clientUtils.requestApiFix('post', constants.api.pharmacy.getAllMethods, body);
    },

    async getDetailMethod(id) {
        return await clientUtils.requestApiFix('get', constants.api.pharmacy.getAllMethods + `/${id}`);
    },

    async updateMethod(id, body) {
        return await clientUtils.requestApiFix('put', constants.api.pharmacy.getAllMethods + `/${id}`, body);
    },
    async deleteMethod(id) {
        return await clientUtils.requestApiFix('delete', constants.api.pharmacy.getAllMethods + `/${id}`);
    },

    async getAllDeliverPartners(size = 1000) {
        return await clientUtils.requestApiFix('get', constants.api.pharmacy.getAllPartners + `?size=${size}`);
    },

    async getDetailPartner(id) {
        return await clientUtils.requestApiFix('get', constants.api.pharmacy.getAllPartners + `/${id}`);
    },

    async deletePartner(id) {
        return await clientUtils.requestApiFix('delete', constants.api.pharmacy.getAllPartners + `/${id}`);
    },

    async updatePartner(id, body) {
        return await clientUtils.requestApiFix('put', constants.api.pharmacy.getAllPartners + `/${id}`, body);
    },

    async createNewPartner(body) {
        return await clientUtils.requestApiFix('post', constants.api.pharmacy.getAllPartners, body);
    },

    async createNewServiceType(id, body) {
        return await clientUtils.requestApiFix(
            'post',
            constants.api.pharmacy.serviceTypes.replace(':partnerId', id),
            body,
        );
    },

    async createNewSetting(id, body) {
        return await clientUtils.requestApiFix(
            'post',
            constants.api.pharmacy.methodSetting.replace(':methodId', id),
            body,
        );
    },
    async updateSetting(methodId, id, body) {
        return await clientUtils.requestApiFix(
            'put',
            constants.api.pharmacy.methodSetting.replace(':methodId', methodId) + `/${id}`,
            body,
        );
    },
    async getSettings(id) {
        return await clientUtils.requestApiFix(
            'get',
            constants.api.pharmacy.methodSetting.replace(':methodId', id) + `?sort=id`,
        );
    },

    async updateServiceType(partnerId, serviceId, body) {
        return await clientUtils.requestApiFix(
            'put',
            constants.api.pharmacy.serviceTypes.replace(':partnerId', partnerId) + `/${serviceId}`,
            body,
        );
    },

    async updateSetting(methodId, settingId, body) {
        return await clientUtils.requestApiFix(
            'put',
            constants.api.pharmacy.methodSetting.replace(':methodId', methodId) + `/${settingId}`,
            body,
        );
    },

    async getServiceTypes(partnerId) {
        return await clientUtils.requestApiFix(
            'get',
            constants.api.pharmacy.serviceTypes.replace(':partnerId', partnerId),
        );
    },

    async deleteServiceType(partnerId, serviceId) {
        return await clientUtils.requestApiFix(
            'delete',
            constants.api.pharmacy.serviceTypes.replace(':partnerId', partnerId) + `/${serviceId}`,
        );
    },

    async deleteSetting(methodId, itemId) {
        return await clientUtils.requestApiFix(
            'delete',
            constants.api.pharmacy.methodSetting.replace(':methodId', methodId) + `/${itemId}`,
        );
    },

    async getBranches({ page, size, id }) {
        return await clientUtils.requestApi(
            'get',
            constants.api.pharmacy.getBranches.replace(':id', id) + `?page=${page}` + `&size=${size}`,
        );
    },

    async getBranchesV2({ page, size, id }) {
        return await clientUtils.requestApi(
            'get',
            constants.api.pharmacy.getBranchesV2.replace(':id', id) + `?page=${page}` + `&size=${size}`,
        );
    },

    async createBranch(id, payload) {
        return await clientUtils.requestApi('post', constants.api.pharmacy.getBranches.replace(':id', id), payload);
    },

    async createBranchV2(id, payload) {
        return await clientUtils.requestApi('post', constants.api.pharmacy.getBranchesV2.replace(':id', id), payload);
    },

    async updateBranch(branchId, hospitalId, payload) {
        return await clientUtils.requestApi(
            'put',
            constants.api.pharmacy.getBranches.replace(':id', hospitalId) + `/${branchId}`,
            payload,
        );
    },

    async updateBranchV2(branchId, hospitalId, payload) {
        return await clientUtils.requestApi(
            'put',
            constants.api.pharmacy.getBranchesV2.replace(':id', hospitalId) + `/${branchId}`,
            payload,
        );
    },

    async getListChatHistory(params) {
        return await clientUtils.requestApi('get', combineUrlParams(constants.api.pharmacy.getListChatHistory, params));
    },

    async exportChatHistoryExcel(reference) {
        return await clientUtils.requestApiExcel(
            'get',
            constants.api.pharmacy.exportChatExcel.replace(':reference', reference),
            null,
            { 'Content-Type': 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet' },
            { responseType: 'arraybuffer' },
        );
    },
};
